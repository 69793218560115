
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: "common-header",
  props: {
    showLogout: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  },
  computed: {
    isAdminSearch(): boolean {
      return this.$route.path === "/admin";
    },
    isAdminCsv(): boolean {
      return this.$route.path === "/admin/csv";
    }
  },
  methods: {
    pushToAdminCsv(): void {
      this.$router.push("/admin/csv")
    },
    pushToSearchPage(): void {
      this.$router.push({path: "/admin"})
    },
  }
});
